/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

[data-amplify-authenticator] {
  --amplify-components-button-link-color: var(--ion-color-primary);
  --amplify-components-button-primary-background-color: var(--ion-color-primary);
  --amplify-components-tabs-item-active-color: var(--ion-color-primary);
  --amplify-components-tabs-item-active-border-color: var(--ion-color-primary);
}

ion-content {
  --background: transparent;
}

html,
body {
  background: transparent;
  --background: transparent;
}

ion-modal.modal-fullscreen {
  --height: 100%;
  --width: 100%;
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  &.top {
    align-items: flex-start;
    --background: transparent;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 0;
    }
  }
}

swiper-container {
  height: 100%;
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

ionic-selectable.ionic-selectable {
  max-width: 100%;
  width: 100%;
}

.margin-top-accurate-location {
  margin-top: 50px;
}

.margin-top-accurate-settings {
  margin-top: 60px;
}

.margin-right-accurate-settings {
  margin-right: -60px;
}

.margin-top-accurate-map-type {
  margin-top: 100px;
}

ion-fab.fab-vertical-bottom.margin-bottom-fix {
  bottom: 70px;
}

.label-icon {
  border: 2px solid var(--ion-color-primary);
  border-radius: 7px;
  padding: 2px;
}

.margin-top-fix {
  margin-top: 60px;
}

.fab-horizontal-left {
  margin-left: 10px;
}

.toast-danger {
  --background: #c42b17;
}

.toast-success {
  --background: #138619;
}

ion-footer {
  min-height: 60px;
}

ion-icon {
  pointer-events: none;
}

ion-app.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;

  ion-modal,
  ion-fab {
    opacity: 0;
  }
}

.gmnoprint,
.gm-style-cc {
  display: none;
}

ion-input .label-text {
  color: var(--ion-color-step-600);
}

// https://nwaveio.atlassian.net/browse/MAIN-3392
ionic-selectable-modal .searchbar-clear-button {
  display: none!important;
}

.cupertino-pane-wrapper .pane {
  z-index: 9999;
  cursor: auto!important;
}
